import TurndownService from "turndown";
import { gfm } from "turndown-plugin-gfm";
import { marked } from "marked";

// Configure marked to support GFM features
marked.setOptions({
  gfm: true,
  breaks: true,
  tables: true,
  headerIds: false,
});

// Converts Markdown to HTML using "marked"
export const markdownToHTML = (markdown) => {
  return marked(markdown);
};

// Converts HTML to Markdown using Turndown with GFM plugin
export const htmlToMarkdown = (html) => {
  const turndownService = new TurndownService({
    headingStyle: "atx",
    codeBlockStyle: "fenced",
    bulletListMarker: "-",
  });

  // Use the GFM plugin to enable table support
  turndownService.use(gfm);

  return turndownService.turndown(html);
};
