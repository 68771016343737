import React from "react";

const Editor = ({ markdown, onChange }) => {
  return (
    <textarea
      style={{
        width: "100%",
        height: "calc(100% - 40px)",
        padding: "10px",
        fontFamily: "monospace",
        fontSize: "1em",
        border: "1px solid #dfe1e6",
        borderRadius: "4px",
        backgroundColor: "#f4f5f7",
        resize: "none",
        boxSizing: "border-box",
        color: "black",
        whiteSpace: "pre-wrap", // Ensure long lines wrap properly
        wordWrap: "break-word", // Prevent words from breaking out of the container
      }}
      value={markdown}
      onChange={(e) => onChange(e.target.value)} // Handle typing in the editor
      placeholder="Enter your markdown here..."
    />
  );
};

export default Editor;
