import React, { useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

const SideNav = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [copyText, setCopyText] = useState('Copy code');
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [showDescription, setShowDescription] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyText('Copied!');
      setTimeout(() => {
        setCopyText('Copy code');
      }, 2000);
    });
  };

  const generatePrompt = () => `
Generate a styled and formal Markdown report for the purpose of converting it to a PDF or Word document. The report should be based on the following topic and requirements:

**Topic**: ${topic || '[Insert your topic here]'}

${description ? `**Description**: ${description}` : ''}

**Requirements**:
1. Create a title page with the title, subtitle (if any), author(s), and date.
2. Generate a table of contents based on the sections in the report.
3. Write an introduction that provides an overview of the topic and background information.
4. Outline the objectives or goals of the report.
5. Describe the methodology used, including data collection and analysis methods.
6. Present the key findings, including any data visualizations (tables, charts, graphs).
7. Discuss the findings, their implications, and comparisons with previous studies or literature.
8. Conclude the report with a summary of the findings and recommendations.
9. Include a references section with all sources cited.
10. Add any appendices for supplementary material or additional information.

Ensure the Markdown includes appropriate headings, subheadings, and formatting to maintain a professional and clean look.
`;

  return (
    <div className="side-nav-container">
      <div className={`side-nav ${collapsed ? 'collapsed' : ''}`}>
        <style>
          {`
            .side-nav {
              width: ${collapsed ? '20px' : '450px'};
              transition: width 0.3s;
              background-color: #0747a6;
              color: white;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              position: relative;
              box-shadow: 2px 0 5px rgba(0,0,0,0.4);
              padding-right: 10px;
              padding-left: 10px;
              overflow-y: auto;
              overflow-x: hidden;
            }

            .side-nav::-webkit-scrollbar {
              width: 8px;
            }

            .side-nav::-webkit-scrollbar-track {
              background: #0747a6;
            }

            .side-nav::-webkit-scrollbar-thumb {
              background-color: #0052CC;
              border-radius: 10px;
              border: 2px solid #0747a6;
            }
          `}
        </style>
        {!collapsed && (
          <div style={{ padding: '10px', color: 'white', width: '100%' }}>
            <div style={{display:"flex", justifyContent:"start"}}>
            <img 
              src="/logo_w.png" 
              alt="Logo" 
              style={{ 
                height: '50px', 
                marginTop: '20px', 
                borderRadius: '8px', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', 
                transition: 'transform 0.2s ease' 
              }} 
              onMouseOver={e => e.currentTarget.style.transform = 'scale(1.08)'}
              onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
            />

            </div>
            <h3 style={{ marginBottom: "0", padding: "0" }}>About This Tool</h3>
            <p style={{ paddingRight: "15px", fontSize: "14px" }}>
              This website enables users to create, edit, and preview Markdown code, providing a seamless way to generate reports. Use the chatbot's outline example prompt to produce a Markdown report, then refine your document here. Once satisfied, download your polished report in PDF or Word format. This tool is especially beneficial for quickly transforming chatbot-generated content into professional reports.
            </p>
            <h3>Prompt Guide</h3>
            <div>
              <div style={{ position: 'relative', marginBottom: '32px' }}>
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder=" "
                  style={{
                    width: 'calc(100% - 35px)',
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    outline: 'none',
                    backgroundColor:'#0747a6',
                    fontSize:'16px', // font size
                    color:'#fff'
                  }}
                />
                <span style={{
                  position: 'absolute',
                  top: '-9px',
                  left: '10px',
                  backgroundColor:'#0747a6',
                  color: '#fff',
                  padding: '0 5px',
                  fontSize: '12px',
                }}>Topic</span>
                <span style={{ position: 'absolute', bottom: '-18px', left: '0px', fontSize: '12px', color: '#ccc' }}>Enter your topic</span>
              </div>
              <div style={{ marginBottom: '15px', color: '#fff' }}>
                <input
                  type="checkbox"
                  checked={showDescription}
                  onChange={() => setShowDescription(!showDescription)}
                  id="toggleDescription"
                  style={{ marginRight: '5px', backgroundColor:'#0747a6' }}
                />
                <label style={{fontSize:'15px'}} htmlFor="toggleDescription"> Description (optional)</label>
              </div>
              {showDescription && (
                <div style={{ position: 'relative', marginBottom: '10px' }}>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder=" "
                    style={{
                      width: 'calc(100% - 35px)',
                      padding: '10px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      outline: 'none',
                      backgroundColor:'#0747a6',
                      color:'#fff',
                      fontSize:'16px', // font size
                      maxWidth:'405px'
                    }}
                  />
                  <span style={{
                    position: 'absolute',
                    top: '-7px',
                    left: '10px',
                    backgroundColor: '#0747a6',
                    color: '#fff',
                    padding: '0 5px',
                    fontSize: '12px',
                  }}>Description </span>
                  <span style={{ position: 'absolute', bottom: '-15px', left: '0px', fontSize: '12px', color: '#ccc' }}>Enter your description</span>
                </div>
              )}
            </div>
            <div style={{ position: 'relative', backgroundColor: '#1e1e1e', padding: '10px', marginRight: '10px', borderRadius: '4px', color: 'white', marginTop: '25px' }}>
              <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', paddingRight: '40px', paddingTop: '10px' }}>{generatePrompt()}</pre>
              <button
                onClick={() => copyToClipboard(generatePrompt())}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: '#6B778C',
                  color: 'black',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                {copyText}
              </button>
            </div>
          </div>
        )}
      </div>
      <div onClick={toggleCollapse} style={{
        cursor: 'pointer',
        backgroundColor: '#0065FF',
        borderRadius: '50%',
        width: '28px',
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '10px',
        left: collapsed ? '25px' : '455px',
        boxShadow: '0 0 5px rgba(0,0,0,0.2)',
        transition: '0.3s',
        zIndex: 9999, // Ensure the arrow button is on top of all other elements
      }}>
        {collapsed ? <MdArrowForward size={18} color="#fff" /> : <MdArrowBack size={18} color="#fff" />}
      </div>
    </div>
  );
};

export default SideNav;
