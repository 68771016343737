import React, { useState } from "react";
import Editor from "./Components/Editor";
import Preview from "./Components/Preview";
import { markdownToHTML, htmlToMarkdown } from "./Components/utils";
import SideNav from "./Components/SideNav";
import { FaCheck } from "react-icons/fa6";
import "./App.css";

const App = () => {
  const [markdown, setMarkdown] = useState("");
  const [htmlPreview, setHtmlPreview] = useState("");
  const [buttonText, setButtonText] = useState("Clear");

  // Handle markdown changes and update the HTML preview
  const handleMarkdownChange = (newMarkdown) => {
    setMarkdown(newMarkdown);
    setHtmlPreview(markdownToHTML(newMarkdown)); // Convert markdown to HTML
  };

  // Handle applying changes from the Preview back to the Editor
  const handleApplyChanges = (newHtml) => {
    const newMarkdown = htmlToMarkdown(newHtml); // Convert HTML back to markdown
    setMarkdown(newMarkdown); // Update editor with the new markdown
  };
  const clearText = () => {
    setMarkdown("");
    setHtmlPreview("");
    setButtonText(<FaCheck fontSize={"14px"} />);
    setTimeout(() => setButtonText("Clear"), 1000); // Reset button text after 1 second
  };

  return (
    <div
      style={{ display: "flex", height: "100vh", backgroundColor: "#f4f5f7" }}
    >
      <SideNav />
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <header
          style={{
            backgroundColor: "#0052CC",
            color: "white",
            padding: "1em",
            textAlign: "center",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <img src="/logo.png" alt="Logo" style={{ height: '50px', marginRight: '10px' }} /> */}
          <h1 style={{ margin: 0, fontFamily: "UnifrakturCook, cursive" }}>
            MD Editor{" "}
          </h1>
        </header>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              padding: "1em",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                flex: 1,
                marginRight: "1em",
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "1em",
                  position: "relative",
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <h2 style={{ margin: "0 0 10px" }}>Editor</h2>
                <button
                  onClick={clearText}
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "15px",
                    backgroundColor: "#f0f0f0",
                    color: "black",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e0e0e0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                >
                  {buttonText}
                </button>
                <Editor markdown={markdown} onChange={handleMarkdownChange} />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  padding: "1em",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  overflow: "hidden",
                }}
              >
                <Preview
                  html={htmlPreview}
                  onApplyChanges={handleApplyChanges}
                />
              </div>
            </div>
          </div>
          <footer
            style={{
              padding: "1em",
              textAlign: "center",
              backgroundColor: "#ebecf0",
              borderTop: "1px solid #dfe1e6",
              display: "flex",
              justifyContent: "center",
              flexShrink: 0,
            }}
          >
            {/* Footer content placeholder */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <a
                  href='/privacy-policy'
                  style={{ margin: "0 10px", color: "#0052cc" }}
                >
                  Privacy Policy
                </a>
                <a
                  href='/terms-of-service'
                  style={{ margin: "0 10px", color: "#0052cc" }}
                >
                  Terms of Service
                </a>
              </div>
              <div style={{ color: "#5E6C84" }}>
                © 2024 Your Company. All rights reserved.
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default App;
