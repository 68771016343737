import jsPDF from "jspdf";
import { parseDocument } from "htmlparser2";
import "jspdf-autotable";

export const downloadPDF = (htmlContent) => {
  const doc = new jsPDF("p", "pt", "a4");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 50;
  let y = margin;
  let currentFontSize = 12;
  let currentFontStyle = "normal";
  let currentFont = "Times";

  doc.setFont(currentFont);
  doc.setFontSize(currentFontSize);
  const dom = parseDocument(htmlContent);

  const checkPageOverflow = (lineHeight) => {
    if (y + lineHeight > pageHeight - margin) {
      addFooter();
      doc.addPage();
      y = margin;
    }
  };

  const addFooter = () => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(10);
    doc.setTextColor(150);
    doc.text(`Page ${pageCount}`, pageWidth - margin, pageHeight - 30, {
      align: "right",
    });
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(currentFontSize);
  };

  const processNodes = (nodes) => {
    nodes.forEach((node) => {
      if (node.type === "tag") {
        switch (node.name) {
          case "h1":
            renderHeading(node, 24, "bold");
            break;
          case "h2":
            renderHeading(node, 20, "bold");
            break;
          case "h3":
            renderHeading(node, 16, "bold");
            break;
          case "p":
            renderParagraph(node);
            break;
          case "strong":
          case "b":
            renderStyledText(node, "bold");
            break;
          case "em":
          case "i":
            renderStyledText(node, "italic");
            break;
          case "ul":
            renderUnorderedList(node);
            break;
          case "ol":
            renderOrderedList(node);
            break;
          case "li":
            break;
          case "a":
            renderLink(node);
            break;
          case "blockquote":
            renderBlockquote(node);
            break;
          case "code":
            renderCodeBlock(node);
            break;
          case "br":
            y += currentFontSize * 1.2;
            break;
          case "table":
            renderTable(node);
            break;
          default:
            if (node.children) {
              processNodes(node.children);
            }
            break;
        }
      } else if (node.type === "text") {
        renderTextNode(node);
      } else if (node.children) {
        processNodes(node.children);
      }
    });
  };

  const renderHeading = (node, fontSize, fontStyle) => {
    checkPageOverflow(fontSize * 1.5);
    doc.setFontSize(fontSize);
    doc.setFont(currentFont, fontStyle);
    const text = getTextContent(node);
    doc.text(text, margin, y);
    y += fontSize * 1.5;
    doc.setFontSize(currentFontSize);
    doc.setFont(currentFont, currentFontStyle);
  };

  const renderParagraph = (node) => {
    if (node.children) {
      const lineHeight = currentFontSize * 1.5;
      const text = getTextContent(node);
      const lines = doc.splitTextToSize(text, pageWidth - 2 * margin);
      lines.forEach((line) => {
        checkPageOverflow(lineHeight);
        doc.text(line, margin, y, { align: "justify" });
        y += lineHeight;
      });
      y += lineHeight * 0.5;
    }
  };

  const renderStyledText = (node, fontStyle) => {
    const previousFontStyle = currentFontStyle;
    currentFontStyle = fontStyle;
    doc.setFont(currentFont, currentFontStyle);
    if (node.children) {
      processNodes(node.children);
    }
    currentFontStyle = previousFontStyle;
    doc.setFont(currentFont, currentFontStyle);
  };

  const renderTextNode = (node) => {
    const text = node.data.trim();
    if (text) {
      const lineHeight = currentFontSize * 1.5;
      const lines = doc.splitTextToSize(text, pageWidth - 2 * margin);
      lines.forEach((line) => {
        checkPageOverflow(lineHeight);
        doc.text(line, margin, y, { align: "justify" });
        y += lineHeight;
      });
    }
  };

  const renderUnorderedList = (node) => {
    if (node.children) {
      node.children.forEach((child) => {
        if (child.name === "li") {
          renderListItem(child, "\u2022");
        }
      });
    }
    y += currentFontSize * 0.5;
  };

  const renderOrderedList = (node) => {
    let index = 1;
    if (node.children) {
      node.children.forEach((child) => {
        if (child.name === "li") {
          renderListItem(child, `${index}.`);
          index++;
        }
      });
    }
    y += currentFontSize * 0.5;
  };

  const renderListItem = (node, bullet) => {
    const lineHeight = currentFontSize * 1.5;
    const text = getTextContent(node);
    const bulletWidth = doc.getTextWidth(`${bullet} `);
    const indent = 18;
    const textIndent = margin + indent + bulletWidth + 5;
    const maxLineWidth = pageWidth - margin - textIndent;

    const lines = doc.splitTextToSize(text, maxLineWidth);
    lines.forEach((line, idx) => {
      checkPageOverflow(lineHeight);
      if (idx === 0) {
        doc.text(`${bullet}`, margin + indent, y);
        doc.text(line, textIndent, y, { align: "left" });
      } else {
        doc.text(line, textIndent, y, { align: "left" });
      }
      y += lineHeight;
    });
  };

  const renderLink = (node) => {
    const href = node.attribs.href || "";
    const text = getTextContent(node);
    const lineHeight = currentFontSize * 1.5;
    checkPageOverflow(lineHeight);
    doc.setTextColor(0, 0, 255);
    doc.textWithLink(text, margin, y, { url: href });
    doc.setTextColor(0, 0, 0);
    y += lineHeight;
  };

  const renderBlockquote = (node) => {
    const lineHeight = currentFontSize * 1.5;
    const text = getTextContent(node);
    const lines = doc.splitTextToSize(text, pageWidth - 2 * margin - 20);
    doc.setDrawColor(150);
    doc.setLineWidth(1);
    doc.line(
      margin + 10,
      y - lineHeight / 2,
      margin + 10,
      y + lines.length * lineHeight - lineHeight / 2
    );
    doc.setFont(currentFont, "italic");
    lines.forEach((line) => {
      checkPageOverflow(lineHeight);
      doc.text(line, margin + 20, y, { align: "left" });
      y += lineHeight;
    });
    doc.setFont(currentFont, currentFontStyle);
    y += lineHeight * 0.5;
  };

  const renderCodeBlock = (node) => {
    const lineHeight = currentFontSize * 1.2;
    const text = getTextContent(node);
    const lines = text.split("\n");
    doc.setFont("Courier", "normal");
    doc.setFillColor(230);
    doc.rect(
      margin,
      y,
      pageWidth - 2 * margin,
      lines.length * lineHeight + 10,
      "F"
    );
    y += 10;
    lines.forEach((line) => {
      checkPageOverflow(lineHeight);
      doc.text(line, margin + 10, y, { align: "left" });
      y += lineHeight;
    });
    doc.setFont(currentFont, currentFontStyle);
    y += lineHeight * 0.5;
  };

  const renderTable = (node) => {
    const tableData = [];
    const tableHeaders = [];
    const processTableRows = (rows, isHeader = false) => {
      rows.forEach((row) => {
        if (row.name === "tr") {
          const rowData = [];
          row.children.forEach((cell) => {
            if (cell.name === "td" || cell.name === "th") {
              const cellText = getTextContent(cell).trim();
              if (isHeader) {
                tableHeaders.push(cellText);
              } else {
                rowData.push(cellText);
              }
            }
          });
          if (!isHeader && rowData.length > 0) {
            tableData.push(rowData);
          }
        }
      });
    };

    const thead = node.children.find((child) => child.name === "thead");
    if (thead && thead.children) {
      processTableRows(thead.children, true);
    }

    const tbody = node.children.find((child) => child.name === "tbody");
    if (tbody && tbody.children) {
      processTableRows(tbody.children);
    } else {
      const rows = node.children.filter((child) => child.name === "tr");
      if (rows.length > 0) {
        if (tableHeaders.length === 0) {
          processTableRows([rows[0]], true);
          processTableRows(rows.slice(1));
        } else {
          processTableRows(rows);
        }
      }
    }

    checkPageOverflow(20);
    doc.autoTable({
      startY: y,
      head: [tableHeaders],
      body: tableData,
      styles: {
        fontSize: currentFontSize,
        lineWidth: 0,
      },
      theme: "plain",
      headStyles: {
        fontStyle: "bold",
        lineWidth: 0,
      },
      bodyStyles: {
        lineWidth: 0,
      },
      margin: { left: margin, right: margin },
      didDrawCell: function (data) {
        if (data.section === "head") {
          const { doc, cell } = data;
          doc.setLineWidth(0.5);
          doc.setDrawColor(0, 0, 0);
          doc.line(
            cell.x,
            cell.y + cell.height,
            cell.x + cell.width,
            cell.y + cell.height
          );
        }
      },
    });

    y = doc.lastAutoTable.finalY + currentFontSize * 4;
  };

  const getTextContent = (node) => {
    let text = "";
    if (node.type === "text") {
      text += node.data;
    } else if (node.children) {
      node.children.forEach((child) => {
        text += getTextContent(child);
      });
    }
    return text;
  };

  processNodes(dom.children);
  addFooter();
  doc.save("document.pdf");
};
